<template>
  <div id="FestivalShowsProgram">
    <QsCard :qlik-ids="['kmBpwVx']" style="grid-column: 1 / span 2" />

    <QsCard :qlik-ids="['AuCYd']" />
    <QsCard :qlik-ids="['EPFKFSS']" />

    <QsCard :qlik-ids="['gfKuEj']" style="grid-column: 1 / span 2" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#FestivalShowsProgram {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 45px 320px 500px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
